import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useScreenBreakPoint from "../../../../hooks/useScreenBreakPoint";
import { Alert } from "reactstrap";
import { resetPassword } from "../../../../apis/auth.apis";
import { toast } from "react-toastify";

function NewPasswordForm(props) {
  const width = useScreenBreakPoint();
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleResetPass = (e) => {
    e.preventDefault();
    if (formData.password !== formData.password_confirmation) {
      setError("Password Does not Match");
    } else {
      setIsActive(true);
      let payload = {
        id:props.token,
        token: props.token,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        //
      };
      resetPassword(payload)
        .then((res) => {
          toast.success(res.message);
          setIsActive(false);
          navigate("/user-management");
        })
        .catch((err) => {
          setError(err.response.data.message);
          setIsActive(false);
        });
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="auto-container d-flex justify-content-center flex-column align-items-center">
        <form
          onSubmit={handleResetPass}
          className={`login-container d-flex flex-column align-items-center  ${
            width === "xs" || width === "sm"
              ? "w-100 justify-content-start mt-5"
              : "w-50 justify-content-center"
          } w-50`}
        >
          {error && (
            <Alert color="danger" isOpen={error ? true : false}>
              <div>{error}</div>
            </Alert>
          )}
          <input
            className="Change_password"
            name="password"
            minLength={10}
            required
            placeholder="Password Minimum 10 Characters"
            type="password"
            value={formData.password ?? ""}
            onChange={handleChange}
          />
          <input
            className="Confirm_password"
            name="password_confirmation"
            required
            value={formData.password_confirmation ?? ""}
            type="password"
            onChange={handleChange}
            placeholder="Confirm Password"
          />
          <div>
            <button
              className="mt-3 forgot_button"
              type="submit"
              disabled={isActive}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewPasswordForm;
